// @flow
import * as React from 'react'
import consecutive from 'consecutive'
import styled from 'styled-components'
import { rem } from 'polished'

import type { AreaTypes } from '../../types/enums/AreaTypes'
import type { LinkViewModel } from '../../types/LinkViewModel'
import type { RelatedListItemViewModel } from '../../types/RelatedListItemViewModel'

import Ribbon from '../../components/Ribbon/Ribbon'
import ListGroup from '../../components/ListGroup/ListGroup'
import ListCard from '../../components/ListCard/ListCard'
import { Link } from '../../components/Link/Link'
import CircleArrowRightIcon from '../../icons/CircleArrowRightIcon'

export type Props = {
  items?: Array<RelatedListItemViewModel>,
  area?: AreaTypes,
  pretitle?: string,
  moreLink?: LinkViewModel,
}

type State = {}

const keyGen = consecutive()

const MoreLinkWrapper = styled.div`
  text-align: right;
`

const MoreLinkText = styled.span`
  margin-right: ${rem(16)};
  font-size: ${({ theme }) => rem(theme.bodyFontSize)};
`

class NewsSpotModule extends React.Component<Props, State> {
  static displayName = 'NewsSpotModule'
  static defaultProps = {}

  render() {
    const { items, pretitle, moreLink } = this.props
    const newsItems = (items || []).map(item => {
      return {
        ...item,
        pretitle: pretitle || 'Nyheder',
        titleLink: true,
        transparent: true,
      }
    })

    return (
      <Ribbon clearChildMargin>
        <ListGroup itemsPerColumn={3}>
          {newsItems.map(item => (
            <ListCard
              {...item}
              key={`NewsSpotModuleItem-${keyGen()}`}
              isNewsItem
            />
          ))}
        </ListGroup>
        {moreLink && (
          <MoreLinkWrapper>
            <Link {...moreLink}>
              <MoreLinkText>{moreLink.label}</MoreLinkText>
              <CircleArrowRightIcon />
            </Link>
          </MoreLinkWrapper>
        )}
      </Ribbon>
    )
  }
}

export default NewsSpotModule
